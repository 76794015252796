import React, { useRef } from "react";
import { Container } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import styled from "styled-components";
import { Breakpoints, Spacing } from "../../styles/styleConstants";
import { HEADER_LEVEL } from "../../utils/commonConst";
import HeaderText from "../common/HeaderText";
import Image from "../common/Image";
import "react-multi-carousel/lib/styles.css";

function ExpertInPocket({ expertInPocketData, headerTextComponent }) {
  const carouselRef = useRef(null);
  return (
    <Container style={{ paddingBottom: 0 }}>
      <PocketWrapper>
        <HeaderWrapper>
          <HeaderText level={HEADER_LEVEL.LEVEL_1}>
            {headerTextComponent ??
              "Feel the confidence of having an expert in your back pocket."}
          </HeaderText>
          <ArrowWrapperTop>
            <ImageWrapper onClick={() => carouselRef.current?.previous()}>
              <Image
                src="v2.0/left-arrow-black.png"
                style={{
                  width: "56px",
                  marginRight: Spacing.md,
                  cursor: "pointer",
                }}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </ImageWrapper>
            <ImageWrapper onClick={() => carouselRef.current?.next()}>
              <Image
                src="v2.0/right-arrow-black.png"
                style={{
                  width: "56px",
                  marginLeft: Spacing.md,
                  cursor: "pointer",
                }}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </ImageWrapper>
          </ArrowWrapperTop>
        </HeaderWrapper>
        <Carousel
          ref={carouselRef}
          infinite={true}
          arrows={false}
          autoPlay={true}
          autoPlaySpeed={5000}
          responsive={{
            desktop: { breakpoint: { max: 5000, min: 0 }, items: 1 },
          }}
        >
          {expertInPocketData.map((ele, i) => (
            <ImageWrapper key={i}>
              <LeftImage>
                <Image
                  src={ele.left_image}
                  className="leftImage"
                  imgStyle={{ objectFit: "contain" }}
                />
              </LeftImage>
              <RightImage>
                <Image
                  src={ele.right_image}
                  className="rightImage"
                  imgStyle={{ objectFit: "contain" }}
                />
              </RightImage>
            </ImageWrapper>
          ))}
        </Carousel>
        <ArrowWrapperBottom>
          <ImageWrapper onClick={() => carouselRef.current?.previous()}>
            <Image
              src="v2.0/left-arrow-black.png"
              style={{
                width: "56px",
                backgroundColor: "transparent",
                marginRight: Spacing.md,
                cursor: "pointer",
              }}
              imgStyle={{
                objectFit: "contain",
              }}
            />
          </ImageWrapper>
          <ImageWrapper onClick={() => carouselRef.current?.next()}>
            <Image
              src="v2.0/right-arrow-black.png"
              style={{
                width: "56px",
                backgroundColor: "transparent",
                marginLeft: Spacing.md,
                cursor: "pointer",
              }}
              imgStyle={{
                objectFit: "contain",
              }}
            />
          </ImageWrapper>
        </ArrowWrapperBottom>
      </PocketWrapper>
    </Container>
  );
}

export default ExpertInPocket;

const PocketWrapper = styled.div`
  margin-top: ${Spacing.xl};
  display: flex;
  flex-direction: column;
  padding-top: 80px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ArrowWrapperTop = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 50%;
  @media (max-width: ${Breakpoints.sm}) {
    display: none;
  }
`;

const ArrowWrapperBottom = styled.div`
  display: flex;
  justify-content: center;
  margin: ${Spacing.lg} auto;
  @media (min-width: ${Breakpoints.sm}) {
    display: none;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  .leftImage,
  .rightImage {
    height: 450px;
    width: 450px;
  }
  @media (max-width: ${Breakpoints.md}) {
    flex-direction: column;
    .leftImage,
    .rightImage {
      height: 200px;
      width: 400px;
    }
  }
  @media (max-width: ${Breakpoints.lg}) {
    .leftImage,
    .rightImage {
      height: 300px;
      width: 400px;
    }
  }
`;

const LeftImage = styled.div`
  display: flex;
  justify-content: start;
  width: 50%;
  margin-top: ${Spacing.xl};
  @media (max-width: ${Breakpoints.md}) {
    width: 80%;
    justify-content: center;
    margin: ${Spacing.xs} auto;
  }
`;

const RightImage = styled.div`
  display: flex;
  justify-content: end;
  width: 50%;
  margin-top: ${Spacing.xl};
  @media (max-width: ${Breakpoints.md}) {
    width: 80%;
    justify-content: center;
    margin: ${Spacing.xs} auto;
  }
`;
