import React from "react";
import styled from "styled-components";
import Image from "./Image";

export default function StarImage(props) {
  const { width = "24px", height = "24px", style } = props ?? {};
  return (
    <StarImageWrapper style={style}>
      <Image src="home/ic-star.png" style={{ width: "24px", height: "24px" }} />
    </StarImageWrapper>
  );
}

const StarImageWrapper = styled.div`
  display: inline-flex;
`;
